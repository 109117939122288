<template>
  <v-rating
    v-model="rating"
    color="rating"
    :length="length"
    v-bind="$attrs"
    @update:model-value="updateRating"
  ></v-rating>
</template>

<script setup>
const props = defineProps({
  modelValue: { type: Number, default: 5 },
  length: { type: Number, default: 5 },
  min: { type: Number, default: 1.5 },
});
const emit = defineEmits(["update:modelValue"]);

let rating = props.modelValue / (10 / props.length);
if (rating < props.min) rating = props.min;
const updateRating = xRating => {
  emit("update:modelValue", xRating * (10 / props.length));
};
</script>
